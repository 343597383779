import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function YouAreTheBestThing() {
  return (
    <Layout>
      <Helmet title="You Are The Best Thing | Ray LaMontagne" />

      <h1>You Are The Best Thing</h1>

      <h2>Ray LaMontagne</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>A</Ch>Baby<Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>It's been a long <Ch>D</Ch>day, <Ch>A</Ch>baby<Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>Things ain't been <Ch>D</Ch>going my way
          </p>
          <p>
            You know I <Ch>A</Ch>need <Ch>E</Ch>you <Ch>F#m</Ch>here
          </p>
          <p>
            To clear my <Ch>E</Ch>mind
          </p>
          <p>
            <Ch>E</Ch>All the time
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>A</Ch>And baby<Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>The way you <Ch>D</Ch>move me, it's <Ch>A</Ch>crazy
            <Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>It's like you see right <Ch>D</Ch>through me
          </p>
          <p>
            <Ch>A</Ch>And make it<Ch>E</Ch> easier<Ch>F#m</Ch>
          </p>
          <p>
            You <Ch>D</Ch>please me, you don't even <Ch>E</Ch>have to try
          </p>
          <p>
            <Ch>E</Ch>Oh, because
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>E</Ch>
          </p>
          <p>
            <Ch>D</Ch>Ever <Ch>E</Ch>happened to <Ch>A</Ch>me
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>A</Ch>Baby<Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>We've come a <Ch>D</Ch>long way, <Ch>A</Ch>baby
            <Ch>E</Ch>
          </p>
          <p>
            <Ch>F#m</Ch>You know, I <Ch>D</Ch>hope and I pray that <Ch>A</Ch>you
            <Ch>E</Ch>believe<Ch>F#m</Ch> me
          </p>
          <p>
            When I say this love will <Ch>E</Ch>never fade away
          </p>
          <p>
            <Ch>E</Ch>Oh, because
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>E</Ch>
          </p>
          <p>
            <Ch>D</Ch>Ever <Ch>E</Ch>happened to <Ch>A</Ch>me
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>F#m</Ch>Both of us have known <Ch>C#m</Ch>love before
          </p>
          <p>
            <Ch>F#m</Ch>To come on up promising <Ch>C#m</Ch>like the spring
          </p>
          <p>
            To walk on out the <Ch>D</Ch>door
          </p>
          <p>
            Hearts are strong, and our <Ch>A</Ch>hearts are kind
          </p>
          <p>
            Well, <Ch>D</Ch>let me tell you just <Ch>E</Ch>exactly what's on my
            mind
          </p>
        </Bridge>
        <Chorus>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>You <Ch>C#7</Ch>are the <Ch>F#m</Ch>best thing<Ch>E</Ch>
          </p>
          <p>
            <Ch>D</Ch>Ever <Ch>E</Ch>happened to <Ch>A</Ch>me
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
